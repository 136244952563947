const reactJsTableData = [
    // { id: 1, name: 'John Doe', age: 30 },
    // { id: 2, name: 'Jane Smith', age: 25 },
    // { id: 3, name: 'Bob Johnson', age: 35 },
    // { id: 4, name: 'Alice Brown', age: 28 },
    // { id: 5, name: 'Eve White', age: 40 },
    // { id: 6, name: 'Michael Green', age: 45 },
    {
        id: 1,
        aspects: "Time to hire the developers",
        inHouse: "3-4 weeks",
        mindsmaven: "48 Hours",
        freelancer: "1-2 weeks",
    },
    {
        id: 2,
        aspects: "Time to start the project",
        inHouse: "2-3 weeks",
        mindsmaven: "4-5 days",
        freelancer: "1-2 weeks",
    },
    {
        id: 3,
        aspects: "Increase the size of team",
        inHouse: "4 - 16 weeks",
        mindsmaven: "24-48 Working Hours",
        freelancer: "1-12 weeks",
    },
    {
        id: 4,
        aspects: "Training costs",
        inHouse: "$ 5000 - $ 7000",
        mindsmaven: "0 $",
        freelancer: "0 $",
    },
    {
        id: 5,
        aspects: "Hourly price",
        inHouse: "$ 40 - $ 50",
        mindsmaven: "$ 20",
        freelancer: "$ 30 - $ 40",
    },
    {
        id: 6,
        aspects: "Communication",
        inHouse: "Direct Communication",
        mindsmaven: "1:1 Communication channel to ensure a successful outcome",
        freelancer: "Depends upon freelancer",
    },
    {
        id: 7,
        aspects: "Project Failure Risk",
        inHouse: "Very High",
        mindsmaven: "Very Low (95% Success Rate)",
        freelancer: "Low",
    },
    {
        id: 8,
        aspects: "Availability",
        inHouse: "Working Hours",
        mindsmaven: "Full-Time - 24/7 Across time zone",
        freelancer: "Part-Time",
    },
    {
        id: 9,
        aspects: "software development process",
        inHouse: "1.5x - 2x",
        mindsmaven: "1x",
        freelancer: "1x",
    },
    {
        id: 10,
        aspects: "Hiring Cost",
        inHouse: "Costly due to hiring, onboarding, and training expenses",
        mindsmaven: "Affordable, skilled to understand needs and implement solutions",
        freelancer: "Flexible for specific tasks or projects",
    },
    {
        id: 11,
        aspects: "Guaranteed software quality",
        inHouse: "Depends on the skills and experience team.",
        mindsmaven: "The quality of the work was high by working with talented Java developers",
        freelancer: "Depending on the skills and reliability of the individual freelancer",
    },
    {
        id: 12,
        aspects: "Project Management Tool",
        inHouse: "Yes",
        mindsmaven: "Yes",
        freelancer: "No",
    },
    {
        id: 13,
        aspects: "Dedicated resources",
        inHouse: "Some",
        mindsmaven: "Yes",
        freelancer: "NA",
    },
    {
        id: 14,
        aspects: "Long-Term Relationship",
        inHouse: "Easy to build long-term relationships with developers.",
        mindsmaven: "Easy to build long-term relationships for future preferences.",
        freelancer: "Depends upon the availability of the freelancer.",
    },
];

export default reactJsTableData;