const reactJsTableData = [
    // { id: 1, name: 'John Doe', age: 30 },
    // { id: 2, name: 'Jane Smith', age: 25 },
    // { id: 3, name: 'Bob Johnson', age: 35 },
    // { id: 4, name: 'Alice Brown', age: 28 },
    // { id: 5, name: 'Eve White', age: 40 },
    // { id: 6, name: 'Michael Green', age: 45 },
    {
        id: 1,
        factor: "Time to hire the developers",
        inHouse: "3-4 weeks",
        mindsmaven: "3-7 days",
        freelancer: "1-2 weeks",
    },
    {
        id: 2,
        factor: "Time to start the project",
        inHouse: "2-3 weeks",
        mindsmaven: "4-5 days",
        freelancer: "1-2 weeks",
    },
    {
        id: 3,
        factor: "Increase the size of team",
        inHouse: "3-4 weeks",
        mindsmaven: "2-3 days",
        freelancer: "1-2 weeks",
    },
    {
        id: 4,
        factor: "Training costs",
        inHouse: "$ 5000 - $ 7000",
        mindsmaven: "0 $",
        freelancer: "0 $",
    },
    {
        id: 5,
        factor: "Hourly price",
        inHouse: "$ 40 - $ 50",
        mindsmaven: "$ 20",
        freelancer: "$ 30 - $ 40",
    },
    {
        id: 6,
        factor: "Communication",
        inHouse: "Direct Communication",
        mindsmaven: "Specific Communication Channels",
        freelancer: "Depends upon freelancer",
    },
    {
        id: 7,
        factor: "Quality Assurance",
        inHouse: "Direct control over the quality",
        mindsmaven: "Best quality standards",
        freelancer: "Depends upon freelancer",
    },
    {
        id: 8,
        factor: "Flexibility",
        inHouse: "Flexible working hours",
        mindsmaven: "Depends upon the agreement",
        freelancer: "Limited working hours",
    },
    {
        id: 9,
        factor: "Control",
        inHouse: "Full control over the project and team",
        mindsmaven: "Shared control according to the agreement",
        freelancer: "Limited control",
    },
    {
        id: 10,
        factor: "Long-Term Relationship",
        inHouse: "Easy to build long-term relationships with developers.",
        mindsmaven: "Easy to build long-term relationships for future preferences.",
        freelancer: "Depends upon the availability of the freelancer.",
    },
];

export default reactJsTableData;